<template>
  <div class="mx-4 my-4 md:mx-8 md:my-8">
        <h1 class="font-r font-medium text-3xl mb-8 pt-3">Assinatura Fácil LGPD</h1>
        <h1 class="font-r font-medium text-2xl">A conta principal está com a assinatura desativada.</h1>
  </div>
</template>

<script>
export default {
    
}
</script>
